import colors from "@elegantstack/solid-ui-theme/src/color-preset";

/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

export default {
  colors: {
    background: `#fbfbfb`,
    headerBg: `transparent`,
    footerBg: `#ffffff`,
    alphaLighter: colors.blue[100],
    alphaLight: colors.blue[300],
    alpha: colors.blue[500],
    alphaDark: colors.blue[600],
    alphaDarker: colors.blue[800],
    // beta (secondary)
    betaLighter: colors.orange[100],
    betaLight: colors.orange[300],
    beta: colors.orange[500],
    betaDark: colors.orange[600],
    betaDarker: colors.orange[800]
  }
}
